import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Moment from "react-moment";

import QuickSearchToolbar from "./QuickSearchToolbar";
import api from "../../api/api";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },

  cancelIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  cancelButton: {
    background: "#dbdbdb",
    "&:hover": {
      background: "#c4c4c4",
    },
  },
  deleteButton: {
    color: "white",
    background: "#c71414",
    marginRight: "5px",
    "&:hover": {
      background: "#ff0000",
    },
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ManageTeamTable({ users, isSubmitted }) {
  const history = useHistory();
  const [selectedMemberId, setSelectedMemberId] = useState("");

  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(users);

  const [showDelete, setShowDelete] = useState(false);

  const [sortModel, setSortModel] = useState([
    {
      field: "createdDate",
      sort: "desc",
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const auth = useSelector((state) => state.authReducer);

  const classes = useStyles();

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    const filteredRows = users.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });

    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(users);
  }, [users]);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleDeleteMember = async () => {
    api
      .delete(`api/User/Delete/${selectedMemberId}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        isSubmitted();
        handleCloseDelete();
        enqueueSnackbar("The member has been deleted successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cols = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "lastLogin",
      headerName: "Last Active",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "state",
      headerName: "Status",
      width: 100,
      align: "center",
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),

      renderCell: (params) => {
        if (params.value === "Active")
          return <Badge color="#8AC747">{params.value}</Badge>;
        else return <Badge color="#9c9c9c">{params.value}</Badge>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      filterable: false,
      align: "center",
      renderCell: (params) => {
        const handleShowDetail = () => {
          history.push(`/manageteamdetail/${params.row.id}`);
        };

        const handleShowDelete = () => {
          setSelectedMemberId(params.row.id);
          setShowDelete(true);
        };

        return (
          <>
            <IconButton onClick={handleShowDetail}>
              <EditIcon />
            </IconButton>
            {params.row.role !== "Account Owner" ? (
              <IconButton onClick={handleShowDelete}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton disabled>
                <DeleteIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {showDelete && (
        <Dialog open={showDelete} onClose={handleCloseDelete}>
          <Box className={classes.dialogTitleContainer}>
            <DialogTitle>Confirmation</DialogTitle>
            <CloseIcon
              onClick={handleCloseDelete}
              className={classes.cancelIcon}
            />
          </Box>
          <DialogContent>
            <Typography variant="body1" component="p">
              Are you sure you want to delete this user?
            </Typography>
            <Box className={classes.buttons}>
              <Button
                type="submit"
                mr={2}
                variant="contained"
                size="small"
                className={classes.deleteButton}
                onClick={handleDeleteMember}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                className={classes.cancelButton}
                size="small"
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <div style={{ height: 470, width: "100%" }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },

            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          rows={rows}
          columns={cols}
          sortModel={sortModel}
          rowsPerPageOptions={[10, 50, 100]}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(" "),
            },
          }}
          onSortModelChange={(model) => setSortModel(model)}
          disableSelectionOnClick
          onRowDoubleClick={(e) => {
            history.push(`/manageteamdetail/${e.row.id}`);
          }}
          scrollbarSize={30}
          hideFooter
        />
      </div>
    </>
  );
}
