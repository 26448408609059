import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";
import SupportTicketTable from "../../components/tables/SupportTicketTable";
import { setIsGridLoading } from "../../redux/actions/datagridActions";

import api from "../../api/api";
import Loader from "../../components/Loader";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Grid,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#7FB741",
    "&:hover": {
      background: "#85c938",
    },
  },
}));

function SupportTickets() {
  const dispatch = useDispatch();

  const gridState = useSelector((state) => state.datagridReducer);

  const [ticketState, setTicketState] = useState({
    tickets: [],
    isLoading: true,
    error: null,
  });

  const history = useHistory();

  const auth = useSelector((state) => state.authReducer);
  const rol = auth?.user?.rol;

  const classes = useStyles();

  useEffect(() => {
    api
      .get(
        `api/SupportTicket?PageSize=${gridState?.size}&PageNumber=${
          gridState?.page + 1
        }&v=1.0`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setTicketState({
            tickets: response.data.data,
            isLoading: false,
            error: null,
          });
          dispatch(setIsGridLoading(false, response.data.totalCount));
        }
      })
      .catch((error) => {
        setTicketState({
          tickets: [],
          isLoading: false,
          error: error.message,
        });
      });
  }, [gridState.page, gridState.size]);

  const handleShowCreateTicket = () => {
    history.push("/createticket");
  };

  if (ticketState?.isLoading) return <Loader />;
  if (ticketState.error)
    return <div style={{ color: "red" }}>{ticketState.error}</div>;

  return (
    <React.Fragment>
      <Helmet title="Support Tickets" />

      <Grid justify="space-between" container spacing={4}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Support Tickets
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Pages</Typography>
            <Typography>Support Tickets</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          {!rol.startsWith("Bromcom") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowCreateTicket}
              className={classes.createButton}
            >
              <AddIcon />
              Create Ticket
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider my={6} />

      <SupportTicketTable tickets={ticketState?.tickets} />
    </React.Fragment>
  );
}

export default SupportTickets;
