/* eslint-disable import/first */
import React from "react";
// import async from "../components/Async";

import {
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Monitor,
  Users,
  UserPlus,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";
import GuestGuard from "../components/GuestGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import PartnerPermissionRequest from "../pages/auth/PartnerPermissionRequest";
// import SignInMs from "../pages/auth/SignInMs";

// import Redirect from "../pages/auth/Redirect";
import Office365Page from "../pages/auth/Office365Page";

//Pages

import Applications from "../pages/pages/Applications";
import CreateApplication from "../pages/pages/CreateApplication";
import ApplicationDetail from "../pages/pages/ApplicationDetail";
import ApiDocumentation from "../pages/pages/ApiDocumentation";
import SupportTickets from "../pages/pages/SupportTickets";
import SupportTicketDetail from "../pages/pages/SupportTicketDetail";
import CreateTicket from "../pages/pages/CreateTicket";
import ManageTeam from "../pages/pages/ManageTeam";
import MemberDetail from "../pages/pages/MemberDetail";
import CreateMember from "../pages/pages/CreateMember";
import PartnerRequests from "../pages/pages/PartnerRequests";
import PartnerAccounts from "../pages/pages/PartnerAccounts";
import PartnerAccountsDetail from "../pages/pages/PartnerAccountsDetail";

import HomePage from "../pages/pages/index";

const applicationsRoutes = {
  id: "Applications",
  path: "/applications",
  header: "Pages",
  icon: <Briefcase />,
  containsHome: false,
  children: null,
  component: Applications,
  guard: AuthGuard,
  permission: [
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
    "PartnerSupport",
  ],
};

const createApplication = {
  id: "Create Application",
  path: "/createapplication",
  header: "Pages",
  icon: <Briefcase />,
  containsHome: false,
  children: null,
  component: CreateApplication,
  guard: AuthGuard,
  permission: ["PartnerAccountOwner", "PartnerAdmin", "PartnerDeveloper"],
};

const applicationDetail = {
  id: "Application Detail",
  path: "/applications/:id",
  header: "Pages",
  icon: <Briefcase />,
  containsHome: false,
  children: null,
  component: ApplicationDetail,
  guard: AuthGuard,
  permission: [
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
    "PartnerSupport",
  ],
};

const apiDocumentationRoutes = {
  id: "API Documentation",
  path: "/apidocumentation",
  icon: <CreditCard />,
  containsHome: false,
  children: null,
  component: ApiDocumentation,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "BromcomDeveloper",
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
  ],
};

const supportTickets = {
  id: "Support Tickets",
  path: "/supporttickets",
  icon: <CheckSquare />,
  containsHome: false,
  children: null,
  component: SupportTickets,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "BromcomDeveloper",
    "BromcomSupport",
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
    "PartnerSupport",
  ],
};
const supportTicketDetail = {
  id: "Support Ticket Detail",
  path: "/supportticketdetail/:ticketId",
  icon: <CheckSquare />,
  containsHome: false,
  children: null,
  component: SupportTicketDetail,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "BromcomDeveloper",
    "BromcomSupport",
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
    "PartnerSupport",
  ],
};

const createTicket = {
  id: "Create Ticket ",
  path: "/createticket",
  icon: <CheckSquare />,
  containsHome: false,
  children: null,
  component: CreateTicket,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "BromcomDeveloper",
    "BromcomSupport",
    "PartnerAccountOwner",
    "PartnerAdmin",
    "PartnerDeveloper",
    "PartnerSupport",
  ],
};

const manageTeam = {
  id: "Manage Team",
  path: "/manageteam",
  icon: <CalendarIcon />,
  containsHome: false,
  children: null,
  component: ManageTeam,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "PartnerAccountOwner",
    "PartnerAdmin",
  ],
};

const manageTeamDetail = {
  id: "Manage Team",
  path: "/manageteamdetail/:userId",
  icon: <CalendarIcon />,
  containsHome: false,
  children: null,
  component: MemberDetail,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "PartnerAccountOwner",
    "PartnerAdmin",
  ],
};

const createMember = {
  id: "Create Member",
  path: "/createmember",
  icon: <CalendarIcon />,
  containsHome: false,
  children: null,
  component: CreateMember,
  guard: AuthGuard,
  permission: [
    "BromcomSuperUser",
    "BromcomAdmin",
    "PartnerAccountOwner",
    "PartnerAdmin",
  ],
};

const partnerRequests = {
  id: "Partner Requests",
  path: "/partner-request",
  icon: <UserPlus />,
  containsHome: false,
  children: null,
  component: PartnerRequests,
  guard: AuthGuard,
  permission: ["BromcomSuperUser", "BromcomAdmin"],
};

const partnerAccounts = {
  id: "Partner Accounts",
  path: "/partner-accounts",
  icon: <Users />,
  containsHome: false,
  children: null,
  component: PartnerAccounts,
  guard: AuthGuard,
  permission: ["BromcomSuperUser", "BromcomAdmin", "BromcomDeveloper"],
};

const partnerAccountDetail = {
  id: "Partner Account Detail",
  path: "/partner-accounts/:partnerId",
  icon: <Users />,
  containsHome: false,
  children: null,
  component: PartnerAccountsDetail,
  guard: AuthGuard,
  permission: ["BromcomSuperUser", "BromcomAdmin", "BromcomDeveloper"],
};

let authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
      guard: GuestGuard,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
      guard: GuestGuard,
    },
    {
      path: "/admin",
      name: "Sign In with Office365",
      component: Office365Page,
      guard: GuestGuard,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/partner-permission-request",
      name: "Partner Permission Request Page",
      component: PartnerPermissionRequest,
    },
    {
      path: "/",
      name: "Home Page",
      component: HomePage,
      guard: GuestGuard,
    },
  ],
  component: null,
};

export const dashboardLayoutRoutes = [
  apiDocumentationRoutes,
  applicationsRoutes,
  applicationDetail,
  createApplication,
  supportTickets,
  supportTicketDetail,
  createTicket,
  manageTeam,
  manageTeamDetail,
  createMember,
  partnerAccounts,
  partnerAccountDetail,
  partnerRequests,
];

export const sidebarRoutes = [
  partnerAccounts,
  partnerRequests,
  applicationsRoutes,
  apiDocumentationRoutes,
  supportTickets,
  manageTeam,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
