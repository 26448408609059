import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";

import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";

import VisibilityIcon from "@material-ui/icons/Visibility";

import QuickSearchToolbar from "./QuickSearchToolbar";

import { useHistory } from "react-router-dom";

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function PartnerAccountsTable({ partners }) {
  const [searchText, setSearchText] = useState("");

  const [rows, setRows] = useState(partners);

  const history = useHistory();

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    const filteredRows = partners.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });

    setRows(filteredRows);
  };
  useEffect(() => {
    setRows(partners);
  }, [partners]);

  // useEffect(() => {
  //   setRows(partners);
  // }, [filterValue]);

  // const onFilterChange = React.useCallback((filterModel) => {
  //   if (
  //     filterModel.items[0].value &&
  //     capitalizeFirstLetter(filterModel.items[0].value) === "Active"
  //   ) {
  //     const newPartners = rows.filter((row) => row.partnerStatusId === 2);
  //     console.log(newPartners);
  //     // setRows();
  //   } else if (
  //     filterModel.items[0].value &&
  //     capitalizeFirstLetter(filterModel.items[0].value) === "Deactive"
  //   ) {
  //     setRows(partners.filter((row) => row.partnerStatusId === 2));
  //   }
  // }, []);

  const cols = [
    {
      field: "organizationName",
      headerName: "Organisation Name",
      flex: 2,
      minWidth: 150,
      filterable: false,
    },
    {
      field: "accountOwner",
      headerName: "Account Owner",
      flex: 1,
      minWidth: 150,
      filterable: false,
    },
    {
      field: "applicationCount",
      headerName: "Number of Applications",
      flex: 1,
      minWidth: 150,
      filterable: false,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      filterable: false,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "equals"
      ),
      renderCell: (params) => {
        if (params.value === "Active")
          return <Badge color="#8AC747">{params.value}</Badge>;
        else return <Badge color="#9c9c9c">{params.value}</Badge>;
      },
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      filterable: false,
      align: "center",
      renderCell: (params) => {
        const onClick = () => {
          history.push(`/partner-accounts/${params.row.id}`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon> Click</VisibilityIcon>
          </IconButton>
        );
      },
    },
  ];

  return (
    <div style={{ height: 470, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },

          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows}
        columns={cols}
        // sortModel={sortModel}
        rowsPerPageOptions={[10, 50, 100]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(" "),
          },
        }}
        // onSortModelChange={(model) => setSortModel(model)}
        disableSelectionOnClick
        scrollbarSize={30}
        onRowDoubleClick={(e) => {
          history.push(`/partner-accounts/${e.row.id}`);
        }}
      />
    </div>
  );
}
